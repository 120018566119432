import * as React from "react"
import "../scss/MockupsProPage.scss"
import Seo from "../components/seo/seo"
import Header from "../components/header/header"
import { navigate } from "gatsby-link"
import Footer from "../components/footer/footer"

const Assistant = props => {
    const staticContents = props.pageContext.staticContent
    const menu = props.pageContext.menu
    const staticHeaderPage = props.pageContext.staticHeaderPage

    const staticAssistantContent = props.pageContext.staticAssistantContent

    return (
        <div>
            <div className="container">
                <Header
                    menu={menu}
                    siteTitle={staticContents["<title>"]}
                    contents={staticHeaderPage}
                    displayLogoLinks={false}
                />
                <Seo
                    keywordsContent={staticContents["<meta> keywords"]}
                    descriptionContent={staticContents["<meta> description"]}
                    title={staticContents["<title>"]}
                />
                <div className="mockups-pro mt-4">
                    <div className="row mb-4">
                        <h1
                            className="col"
                            dangerouslySetInnerHTML={{
                                __html: staticAssistantContent["Page Header"],
                            }}
                        />
                    </div>
                    <img src={require(`../images/${staticAssistantContent["Page Image"]}`).default} alt="Assistant background" className="mockups-pro-page mb-4" />
                    <div className="row">
                        <h1 className="col">{staticAssistantContent["Page Header Details"]}</h1>
                    </div>
                    <div className="row mt-6">
                        <h3
                            className="col"
                            dangerouslySetInnerHTML={{
                                __html: staticAssistantContent["Page Promo Text"]

                            }}
                        />
                    </div>
                    <div className="special-padding mt-4 mb-6">
                        <button
                            type="button"
                            onClick={() => navigate(staticAssistantContent["Button Link"])}
                            className="btn btn-primary btn-lg col"
                        >
                            {staticAssistantContent["Button Text"]}
                        </button>
                        <div className="col-lg-7"></div>
                    </div>
                </div>
            </div>
            <Footer
                recaptchaSettings={props.pageContext.recaptchaSettings}
                staticContactsForm={props.pageContext.staticContactsForm}
                staticContactsPage={props.pageContext.staticContactsPage}
            />
        </div>
    )
}

export default Assistant
